import React from "react";

import "./HeaderPlanFrentes.css"

export function HeaderPlanFrentes({ headers }) {
  const colors = ["#2C4352", "#3167A9", "#4A86E8", "#01B7C4", "#139EA8", "#097B83", "#023E53", "#F89821"]

  return (
    <section className="control-frentes-section">
      <ul className="header-control-frentes">
        {headers.map((item, index) => {
          return (
            <li className="header-item" key={item.formatedLabel}>
              <p>{item.label}</p>
              <div className="color-plan" style={{ backgroundColor: colors[index] }} /> 
            </li>
          )
        })}
      </ul>
    </section>
  )
}