import React from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { useGetActividadFrentes } from "../../hooks/controlFrentes/useGetActividadFrentes";
import { CustomYAxis } from "../../views/ControlDeFrentes/CustomYAxis";
import { HeaderPlanFrentes } from "../../views/ControlDeFrentes/HeaderPlanFrentes";
import { MeetsTarget } from "../../views/ControlDeFrentes/MeetsTarget";
import { PlanNoCumpleNotify } from "../../views/ControlDeFrentes/PlanNoCumpleNotify";
import {
  colors,
} from "../../views/ControlDeFrentes/placeholder";

const BAR_HEIGHT = 47.2;
const parsedYs = {
  3: 1,
  2: 0.5,
  1.5: 0.5,
  1: 0,
  0: 0
};

function calculatePlan(frente, headers) {
  return headers.reduce((acc, header) => {
    const parsedHeaderValue =
      parsedYs[frente[header.formatedLabel]] || 0;

    const parsedHeaderPlanValue =
      parsedYs[frente[`plan_${header.formatedLabel}`]] || 0;

    const currentPlanStatus = parsedHeaderPlanValue - parsedHeaderValue;

    const newAcc = acc + currentPlanStatus;

    return newAcc;
  }, 0);
}

export const RangeBar = ({ data, loading, error }) => {
  const { activities, loadingActivities } = useGetActividadFrentes();

  const newActivities = [...activities].concat({ label: "Plan", value: 8, formatedLabel: "plan" });

  const draft = newActivities?.map((header) => {
    return {
      name: header.label,
      data: data?.map((item) => {
        const isPlan = header.label === "Plan";
        const y = !isPlan ? parsedYs[item[`${header.formatedLabel}`]] : calculatePlan(item, activities);

        return {
          x: item.frente,
          y: y
        };
      })
    };
  });

  const transformData = (data) => {
    const map = new Map();

    data.forEach((item) => {
      item.data.forEach((el) => {
        if (!map.has(el.x)) {
          map.set(el.x, { name: el.x });
        }

        const entry = map.get(el.x);

        entry[item.name] = el.y;
      });
    });

    return Array.from(map.values());
  };

  const parsedData = transformData(draft);

  const dynamicHeight = BAR_HEIGHT * 2.05 + BAR_HEIGHT * parsedData.length - 1;

  return (
    <section
      style={{
        maxWidth: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "auto auto 1fr 1fr 200px",
        gridTemplateRows: "auto 1fr",
        marginBlockStart: "2.5em",
      }}>
      {data.length < 1 ? (
        <section style={{
          gridRowStart: 2,
          gridColumnStart: 2,
          gridColumnEnd: 4
        }}>selecciona un turno y una mina</section>
      ) : loading ? (
        <p>cargando...</p>
      ) : error ? (
        <p>errorPlanControlFrentes</p>
      ) : (
        <>
        <HeaderPlanFrentes headers={activities} />
        <CustomYAxis columns={parsedData} />
        <section style={{
          gridRowStart: 2,
          gridColumnStart: 2,
          gridColumnEnd: 4,
        }}>
        <ResponsiveContainer
          width="100%"
          minHeight={dynamicHeight}
          height={dynamicHeight}
          >
          <BarChart data={parsedData} layout="vertical" barCategoryGap={2.5}>
            <XAxis
              type="number"
              tickLine={false}
              underlineThickness={false}
              tickCount={8}
              tick={{ fontSize: 0 }}
              domain={[0, 7]}
              />
            <YAxis
              dataKey="name"
              type="category"
              padding={{ top: 25, bottom: 20 }}
              tickLine={false}
              hide={true}
            />
            {Object.keys(parsedData?.[0])
              .filter((key) => key !== "name")
              .map((key, index) => (
                <Bar
                  maxBarSize={BAR_HEIGHT}
                  key={index}
                  dataKey={key}
                  fill={colors[index]}
                  stackId="a"
                />
              ))}
            <CartesianGrid vertical horizontal={false} stroke="#E9EBF8" />
          </BarChart>
        </ResponsiveContainer>
        </section>
        <MeetsTarget data={data} />
        <PlanNoCumpleNotify data={data} />
        </>
      )}
    </section>
  );
};
