import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { Container, makeStyles } from "@material-ui/core";
import { Card, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import NavBar from "../../components/organisms/NavBar";
import SectionHeader from "../../components/molecules/SectionHeader";

import IconFrentes from '../../assets/icons/frentes_icon.svg'
import IconoControlTiempo from '../../assets/icons/icono-control-tiempo.png';

import { TimeLineChart } from "./TimeLineChart";
import { TablaDetalleEquiposFrentes } from "./TablaDetalleEquiposFrentes";
import { useGetEncuentrosFrentes } from "../../hooks/useGetEncuentrosFrentes";
import PantallaCarga from "../../components/molecules/PantallaCarga";
import { format } from "date-fns";
import { GenericDateRange } from "../../components/organisms/GenericDateRange";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useGetUltimosEncuentrosFrente } from "../../hooks/useGetUltimosEncuentrosFrente";
import { CircularProgress } from '@material-ui/core'
import { TodayMinusNdays } from "../../commons/Formulas";
import { usePageTimingContext } from "../../context/PageTimingContext";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico";
import { CategoriasGrafico } from "../../components/molecules/CategoriasGrafico";

const useStyles = makeStyles({
  iconClick:{
    width:"25px",
    color: "#2f4554",
    cursor: "pointer",
    "&:hover": {
      color: "#00b7c4"
    }
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "8px 0",
    color: "white",
    "&:hover": {
      backgroundColor: "#253642",
    },
    "&:disabled": {
      backgroundColor: "gray",
    },
  },
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "0px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    pointerEvents:"none"
  },
  buttonUnselected: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "0px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4",
      color:"#fff"
    }
  },
  prodDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  },
  prodValue:{
    fontSize: "20px",
    fontWeight:"bold"
  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        /* fontSize:"14.4px" */
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
    },
  }
});

const ControlFrentes = ({ idMina, nombreMina, rutaMina}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const { sendTime } = usePageTimingContext();
  
  const [fechaActual, setFechaActual] = useState(new Date());
  const [openModalDate, setOpenModalDate] = useState(false);
  const [fechaInicio, setfechaInicio] = useState(() => {
    /*const today = new Date();
    today.setDate(today.getDate() - 3);
    return today;*/
    return TodayMinusNdays(3)
  });
  const [fechaTermino, setfechaTermino] = useState(new Date())
  const { ultimoEncuentroFrente, loadingUltimoEncuentroFrente } = useGetUltimosEncuentrosFrente(idMina);
  // const {EncuentrosFrentesMaquina,loadingEncuentrosFrentesMaquinas} =  useGetEncuentrosFrentesByMaquinas(idMina,format(new Date(fechaInicio),"yyyy-MM-dd HH:mm:ss"),format(new Date(fechaTermino),"yyyy-MM-dd HH:mm:ss"))
  const {EncuentrosFrentes, loadingEncuentrosFrentes} = useGetEncuentrosFrentes(idMina,format(new Date(fechaInicio),"yyyy-MM-dd"),format(new Date(fechaTermino),"yyyy-MM-dd"));
  
  let dataRutaBreadCrumb = [
    { id: 1, endpoint: "/", texto: `Mine-Watch` },
    { id: 2, texto: `${t("minaSingular")} ${nombreMina}` },
    { id: 3, texto: t("control_frentes"), destacar:true },
  ];
  
  let categoriasGrafico=[
    {id:0,nombre:t("perforacion"), color:"#b33b9b"},
    {id:1,nombre:"Telehandler", color:"#4185f2"},
    {id:2,nombre:t("limpieza_scoop"), color:"#5cbf3d"},
    {id:3, nombre: idMina != 16 ? t("refuerzo") : t("refuerzo_buritica"), color:"#ff7325"},
    {id:4,nombre:t("sin_info"),color:"#d6dce4"}
  ]

  useEffect(() => {
    return () => {
      sendTime(location.pathname)
    }
  }, [])

  if(loadingEncuentrosFrentes){
    return (
      <PantallaCarga loading={loadingEncuentrosFrentes}/>
    )
  }

  return (
    <>
      <NavBar />
      <Container>
        <br />
        <br />
        <Grid container alignItems="center" justifyContent="start">
          <BreadCrumbsGenerico
            dataRuta={dataRutaBreadCrumb}
            nombreMina={nombreMina}
          />
        </Grid>
        <br/>
        <br/>
        <Card sx={{padding:"25px"}}>
          <Grid container justifyContent={'flex-start'} >
            <Grid item xs={6}>
              <SectionHeader 
                  title={t("control_frentes")}
                  subtitle={(i18n.language === 'es')?
                  (`Datos hasta el día ${fechaActual.getDate()} de ${fechaActual.toLocaleString('es-ES', { month: 'long' }).substr(0,1).toUpperCase()}${fechaActual.toLocaleString('es-ES', { month: 'long' }).substr(1)}`)
                :
                  (`Data until ${fechaActual.toLocaleString('en-EN', { month: 'long', year:'numeric', day:'numeric' })}`)
                }
                  icon={<img src={IconFrentes} width="125px" alt=" Icono control de frentes"/>}
                  colorTitle="#00B7C4"
                  aumentarFuente={3}
                  bold={true}
                />
            </Grid>
          </Grid>
          <br/>
          <br/>
          <br/>
          <SectionHeader 
            title={t("equipos_en_frentes")}
            // subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
            subtitle={
              
              <Grid direction={"row"} container spacing={1} alignItems={'center'}>
                <Grid item> {format(new Date(fechaInicio),"dd-MM-yyyy")+" - "+format(new Date(fechaTermino),"dd-MM-yyyy")}</Grid>
                {/* <Grid item><button onClick={() => setOpenModalDate(true)}>calendar</button></Grid> */}
                <Grid item><CalendarMonthIcon onClick={() => setOpenModalDate(true)} style={{ fontSize: "25px", marginRight: "10px", cursor:'pointer', color:'#000000'}}/></Grid>
              </Grid>
            }
            icon={<img src={IconoControlTiempo} width="35px" alt=" Icono Control de Tiempo"/>}
          />
          <br/>
          <br/>
          <CategoriasGrafico categoriasGrafico={categoriasGrafico}/>
          
          {loadingEncuentrosFrentes ? (
            <CircularProgress size={100}/>
          ) : (
            <TimeLineChart data={EncuentrosFrentes} fechaInicioAux={fechaInicio} fechaTerminoAux={fechaTermino}/>
          )}

          <br />
          <br />
          <br />
          {
          !loadingEncuentrosFrentes && (
          <TablaDetalleEquiposFrentes 
            data={EncuentrosFrentes} 
            loadingEncuentrosFrentes={loadingEncuentrosFrentes}
            idMina={idMina} 
            nombreMina={nombreMina} 
            ultimoEncuentroFrente={ultimoEncuentroFrente}
            loadingUltimoEncuentroFrente={loadingUltimoEncuentroFrente}
            fechaInicio={fechaInicio}
            fechaTermino={fechaTermino}
          />
          )
          }

        </Card>
        
      </Container>
     
      <GenericDateRange 
        open={openModalDate}
        onClickClose={setOpenModalDate}
        fechaFin={fechaTermino}
        setFechaFin={setfechaTermino}
        fechaInicio={fechaInicio}
        setFechaInicio={setfechaInicio}
        titulo={"Fechas"}
        subtitulo={"Máximo 3 dias"}
        icono={<CalendarMonthIcon style={{ fontSize: "25px", marginRight: "10px"}}/>}
        iconoTextoFooter={<DoneOutlineIcon style={{marginRight:'5px'}}/>}
      />
    </>
  );
};

export default ControlFrentes;
