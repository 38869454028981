import React, { useContext, useState, useEffect } from "react";
import Status from "../authentication/Status";
import Login from "../authentication/Login";
import Settings from "../authentication/Settings";
import { AccountContext } from "../authentication/Account";
import { GeneralMinaContext } from "../context/GeneralMina";
import Signup from "../authentication/Signup";
import ForgotPassword from "../authentication/ForgotPassword";
import Tablausuario from "./organisms/TablaUsuario";
import SimpleBackdrop from "./molecules/BackDrop";
import Productividad from "../views/Productividad";
import Produccion from "../views/Produccion";
import Registros from "../views/Registros"
import Tablausuarioempresa from "./organisms/TablaUsuarioempresa";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../views/Dashboard";
import Equipo from "../views/Equipo";
import FuncionamientoDiario from "../views/FuncionamientoDiario";
import { CircularProgress } from "@mui/material";
import NivelCombustible from "../views/NivelCombustible";
import Operaciones from "../views/Operaciones";
import PersonasDemo from "../views/PersonasDemo";
import ControlDiario from "../views/ControlDiario";
import OperacionesMaquina from "../views/OperacionesMaquina";
import Administracion from "../views/Administracion";
import { JustificacionMacro } from "../views/Justificacion/JustificacionMacro";
import { OperacionesMaquinaJumbos } from "../views/OperacionesMaquina/OperacionesMaquinaJumbos";
import { getRutaAreaOperacion } from "../services/general";
import QuickSight from "../views/Quicksight";
import ControlFrentes from "../views/ControlFrentes";
import { useGetRutaRedireccionEmpresa } from "../hooks/general/useGetRutaRedireccionEmpresa";
import IconoDumperWhite from '../assets/icons/dumper-white.png';
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavDrawerEquipos } from "./organisms/NavDrawerEquipos";
import Solicitudes from "../views/Solicitudes";
import { PageTimingProvider } from "../context/PageTimingContext";
import { Analytics } from "../views/Analytics";
import { useLocation } from "react-router-dom";
import ControlDeFrentes from "../views/ControlDeFrentes";

const useStyles = makeStyles({
  botonEquipos: {
    "&:hover": {
      backgroundColor: "rgb(0, 183, 196)"
    },
    textAlign: 'center',
    position: 'fixed',
    bottom: '5%',
    left: '50%',
    transform: 'translateX(780%)',
    zIndex: 999,
    borderRadius: '35px',
    backgroundColor: '#2f4554',
  },
});

const Main = () => {
  const classes = useStyles();
  
  const location = useLocation();
  const { getSession } = useContext(AccountContext);
  const {onHandleSetEndpoint, onHandleGetEndpoint,NavDrawerEquipo: AliasNavDrawerEquipo, setNavDrawerEquipo} = useContext(GeneralMinaContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loginOk, setLoginOk] = useState(false);

  const [idEmpresa, setIdEmpresa] = useState(0);
  const [idAreaOperacion, setidAreaOperacion] = useState(0)

  const {RutaRedireccionEmpresa, loadingRutaRedireccionEmpresa} = useGetRutaRedireccionEmpresa(idEmpresa);
  useEffect(() => {
    getSession().then((session) => {
      setLoggedIn(true);
      const { idToken } = session;
      const { jwtToken, payload } = idToken;
      const { email, email_verified } = payload;
      try {
        const userType = session["custom:role"];
        const userIdEmpresa = session["custom:id_empresa"];
        const userIdOperacion = session["custom:id_operacion"];
        const userIdAreaOperacion = session["custom:id_area_operacion"];

        setIdEmpresa(userIdEmpresa);
        setidAreaOperacion(userIdAreaOperacion)

        getRutaAreaOperacion(parseInt(userIdEmpresa))
        .then(resp => onHandleSetEndpoint(resp.data))
        .catch(err => console.log(err))

        //setRole(userType);
      } catch (err) {
        console.log(err);
      }
      })
      .catch((e)=>
        setLoadingPage(false)
      )
  }, [loginOk]);
  

  useEffect(() => {
    if(idEmpresa !== 0 && onHandleGetEndpoint().length > 0){
      setLoadingPage(false);
    }
  }, [idEmpresa, onHandleGetEndpoint]);
  return (
    <>
      {
        !loadingPage ? (
          !loggedIn ? (
            <>
              <Status />
              <Login logOk = {setLoginOk}/>
              <Settings />
            </>
          )      
          : 
          (
            <PageTimingProvider idAreaOperacion={idAreaOperacion}>
            <Switch>
              <Route exact path="/personas-demo" render={()=><PersonasDemo idEmpresa={4} idMina={4} nombreMina="San Antonio" rutaMina="sanantonio"/>}/>
              <Route exact path="/nivelcombustible" render={()=><NivelCombustible/>}/>
              <Route path="/encuentros">
                <Dashboard />
              </Route>
              <Route exact path="/maquina" render={(props)=> <Equipo {...props}/>}/>
              <Route exact path="/funcionamientodiario" render={(props)=><FuncionamientoDiario {...props} />}/>
              <Route exact path="/register" component={Signup} />
              {/* <Route exact path="/registrarusuarioempresa" component={SignupCompanyUser} /> */}
              <Route exact path="/login" component = {Main} />
              <Route
                exact
                path="/recuperacontrasena"
                component={ForgotPassword}
              />
              <Route exact path="/tablausuario" component={Tablausuario} />
              <Route exact path="/tablausuarioempresa" component={Tablausuarioempresa} />
              <Route exact path="/productividad" component={Productividad} />

              { idEmpresa &&
                <Route exact path="/operaciones/maquina" >
                  <OperacionesMaquina />
                </Route>
              } 
              { idEmpresa &&
                <Route exact path="/operaciones/maquina/jumbo" >
                  <OperacionesMaquinaJumbos />
                </Route>
              } 

            {
              onHandleGetEndpoint().map(ruta =>{
                // TODO: Creacion de api para controlar rutas especificas
                let rutas = [];
               
                
                if(idEmpresa == 23){
                  rutas = [
                    <Route key={`control-diario-${ruta.endpoint}`} exact path={`/control_diario/${ruta.endpoint}`}>
                      <Operaciones esMinaSubterrane={ruta.es_subterranea} idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} rutaMina={ruta.endpoint}/>
                    </Route>,
                    <Route exact path={`/justificacion/macro/${ruta.endpoint}`}>
                      <JustificacionMacro idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} rutaMina={ruta.endpoint} />
                    </Route>,
                    <Route key={`control_de_frentes-${ruta.endpoint}`} exact path={`/control_de_frentes/${ruta.endpoint}`}>
                      <ControlDeFrentes idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} rutaMina={ruta.endpoint} />
                    </Route>,
                     <Route exact path={`/dashboard/${ruta.endpoint}`}>
                     <QuickSight idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} endpointQuickSight={ruta.endpoint_quicksight} zonaHoraria={ruta.zona_horaria} diff_segundos={ruta.diff_segundos}/>
                    </Route>,
                  ];
                }else{
                  rutas = [
                    <Route key={`produccion-${ruta.endpoint}`} exact path={`/produccion/${ruta.endpoint}`}>
                      <Produccion idEmpresa={ruta.id_empresa} idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} rutaMina={ruta.endpoint} />
                    </Route>,
                    <Route exact path={`/administracion/${ruta.endpoint}`}>
                      <Administracion idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} esMinaSubterranea={ruta.es_subterranea} />
                    </Route>,
                    <Route exact path={`/justificacion/macro/${ruta.endpoint}`}>
                      <JustificacionMacro idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} rutaMina={ruta.endpoint} />
                    </Route>,
                    <Route exact path={`/dashboard/${ruta.endpoint}`}>
                        <QuickSight idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} endpointQuickSight={ruta?.endpoint_quicksight} zonaHoraria={ruta.zona_horaria} diff_segundos={ruta.diff_segundos} />
                    </Route>,
                    <Route exact path={`/control_frentes/${ruta.endpoint}`}>
                        <ControlFrentes idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} rutaMina={ruta.endpoint}/>
                    </Route>,
                  ];

                  if(ruta.id_mina == 24){
                    rutas.push(
                    <Route key={`control-diario-${ruta.endpoint}`} exact path={`/control_diario/${ruta.endpoint}`}>
                      <Operaciones esMinaSubterrane={ruta.es_subterranea} idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} rutaMina={ruta.endpoint}/>
                    </Route>
                    )
                    ruta?.endpoint_externo?.map((rutaAux) =>{
                      rutas.push(
                        <Route exact path={`/${rutaAux.nombre}/${ruta.endpoint}`}>
                          <QuickSight idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} endpointQuickSight={rutaAux.endpoint} zonaHoraria={ruta.zona_horaria} diff_segundos={ruta.diff_segundos} recarga_automatica={rutaAux?.recarga_automatica}/>
                        </Route>
                      )
                    })
                  }else{
                    rutas.push(
                    <Route key={`operaciones-${ruta.endpoint}`} exact path={`/operaciones/${ruta.endpoint}`}>
                     <Operaciones esMinaSubterrane={ruta.es_subterranea} idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} rutaMina={ruta.endpoint}/>
                    </Route>
                  )
                  rutas.push(
                    <Route exact path={`/control_diario/${ruta.endpoint}`}>
                      <ControlDiario idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} />
                    </Route>
                  )
                }
             }
                
                if(idEmpresa == 1){
                  rutas.push(
                  <Route exact path={`/solicitudes/${ruta.endpoint}`}>
                    <Solicitudes idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} />
                  </Route>
                  );
                  rutas.push(
                  <Route exact path={`/analytics/${ruta.endpoint}`}>
                    <Analytics idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} />
                  </Route>
                    );

                    ruta?.endpoint_externo?.map((rutaAux, index) =>{
                      rutas.push(
                        <Route key={'embedded'+index} exact path={`/${rutaAux.nombre}`}>
                          <QuickSight idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} endpointQuickSight={rutaAux.endpoint} zonaHoraria={ruta.zona_horaria} diff_segundos={ruta.diff_segundos} recarga_automatica={rutaAux?.recarga_automatica} />
                        </Route>
                      )
                    })
                }
                {
                  ruta?.endpoint_externo.length > 0 && (
                    ruta?.endpoint_externo?.map((rutaAux) =>{
                      rutas.push(
                        <Route exact path={`/${rutaAux.nombre}/${ruta.endpoint}`}>
                          <QuickSight idMina={ruta.id_mina} nombreMina={ruta.nombre_publico} endpointQuickSight={rutaAux.endpoint} zonaHoraria={ruta.zona_horaria} diff_segundos={ruta.diff_segundos} recarga_automatica={rutaAux?.recarga_automatica}/>
                        </Route>
                      )
                    })
                  )
                }

                return (rutas)
              })
            }              
              <Route exact path="/actualizarusuario" component={Settings} />
              <Route exact path="/recuperarcontrasena" component={ForgotPassword} />
              <Route exact path="/backdrope" component={SimpleBackdrop} />
              <Route exact path="/registros" component={Registros}/>
              <Route exact path ="/controlfrentes" component={ControlFrentes}/>

               {idEmpresa !== 0 &&
                <Route path="/" render={()=>(<Redirect to={`/operaciones/${RutaRedireccionEmpresa?.ruta_redireccion}`}/>)}/>
              }
              {idEmpresa !== 0 &&
                <Route path="/produccion" render={()=>(<Redirect to={`/operaciones/${RutaRedireccionEmpresa?.ruta_redireccion}`}/>)}/>
              }
            </Switch>
             <div

             style={{display:((location?.pathname?.split("/")[1] == "operaciones" && location?.pathname?.split("/")[2] != "laschispas") ||
              (location?.pathname?.split("/")[1] == "control_diario" && location?.pathname?.split("/")[2] == "laschispas"))
              ? "inline" : 'none'}}
             className={classes.botonEquipos}>
               <IconButton
                   //className={classes.menuButton}
                   color="inherit"
                   aria-label="menu"
                   onClick={() =>setNavDrawerEquipo(true)}
                 >
                   <img
                     alt="Icono Dumper"
                     src={IconoDumperWhite}
                     width={'45px'}
                     style={{color:'white'}}
                   />
                 </IconButton>
             </div>
             <NavDrawerEquipos
             drawerOpened={AliasNavDrawerEquipo}
             toggleDrawer={setNavDrawerEquipo}
             />
            </PageTimingProvider>
          )
        )
        :
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", marginTop:"25%"
        }}>
          <CircularProgress style = {{color: "#2f4554"}} size = {100}/>
        </div>

      }
    </>
  );
};

export default Main;
