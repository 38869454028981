export const colors = [
  "#2C4352",
  "#3167A9",
  "#4A86E8",
  "#01B7C4",
  "#139EA8",
  "#097B83",
  "#023E53",
  "#F89821"
];

export const notificaciones = [
  {
    ubicacion: "Rampa 950",
    mensaje: "40% Mantenimiento programado",
    metaActual: 3,
    metaFinal: 10,
    metasCompletadas: 0
  },
  {
    ubicacion: "Muck Bay 1000",
    mensaje: "50% Fallo del equipo en el frente",
    metaActual: 7,
    metaFinal: 15,
    metasCompletadas: 1
  },
  {
    ubicacion: "Frente 1042 N",
    mensaje: null,
    metaActual: 3.5,
    metaFinal: 10,
    metasCompletadas: 0
  },
  {
    ubicacion: "Frente 1042 S",
    mensaje: "55% Falla de energia",
    metaActual: 4,
    metaFinal: 10,
    metasCompletadas: 0
  },
  {
    ubicacion: "Frente 1005 N",
    mensaje: null,
    metaActual: 1,
    metaFinal: 5,
    metasCompletadas: 0
  },
  {
    ubicacion: "Frente 1005 S",
    mensaje: null,
    metaActual: 8,
    metaFinal: 10,
    metasCompletadas: 4
  },
  {
    ubicacion: "Muck Bay 1042",
    mensaje: "35% Mantenimiento programado",
    metaActual: 4,
    metaFinal: 10,
    metasCompletadas: 0
  },
  {
    ubicacion: "Rampa 980",
    mensaje: null,
    metaActual: 2,
    metaFinal: 10,
    metasCompletadas: 0
  }
];

export const headers = [
  {
    name: "Rezagado",
    formatedName: "rezagado"
  },
  {
    name: "Amacize",
    formatedName: "amacize"
  },
  {
    name: "Fortificación",
    formatedName: "fortificacion"
  },
  {
    name: "Geología",
    formatedName: "geologia"
  },
  {
    name: "Barrenación",
    formatedName: "barrenacion"
  },
  {
    name: "Carga",
    formatedName: "carga"
  },
  {
    name: "Vol",
    formatedName: "vol"
  }
];


export const dataRecharts2 = [
  {
    name: "Rezagado",
    data: [
      { x: "Rampa 950", y: 1 },
      { x: "Muck Bay 1000", y: 1 },
      { x: "Frente 1042 N", y: 1 },
      { x: "Frente 1042 S", y: 1 },
      { x: "Frente 1005 N", y: 1 },
      { x: "Frente 1005 S", y: 1 },
      { x: "Muck Bay 1042", y: 1 },
      { x: "Rampa 980", y: 1 },
    ]
  },
  {
    name: "Amacize",
    data: [
      { x: "Rampa 950", y: 1 },
      { x: "Muck Bay 1000", y: 1 },
      { x: "Frente 1042 N", y: 1 },
      { x: "Frente 1042 S", y: 1 },
      { x: "Frente 1005 N", y: 1 },
      { x: "Frente 1005 S", y: 1 },
      { x: "Muck Bay 1042", y: 1 },
      { x: "Rampa 980", y: 1 },
    ]
  },
  {
    name: "Fortificación",
    data: [
      { x: "Rampa 950", y: 0.8 },
      { x: "Muck Bay 1000", y: 1 },
      { x: "Frente 1042 N", y: 0.8 },
      { x: "Frente 1042 S", y: 1 },
      { x: "Frente 1005 N", y: 0.8 },
      { x: "Frente 1005 S", y: 1 },
      { x: "Muck Bay 1042", y: 0.8 },
      { x: "Rampa 980", y: 1 },
    ]
  },
  {
    name: "Geología",
    data: [
      { x: "Rampa 950", y: 0 },
      { x: "Muck Bay 1000", y: 0 },
      { x: "Frente 1042 N", y: 0 },
      { x: "Frente 1042 S", y: 1 },
      { x: "Frente 1005 N", y: 0 },
      { x: "Frente 1005 S", y: 1 },
      { x: "Muck Bay 1042", y: 0 },
      { x: "Rampa 980", y: 1 }
    ]
  },
  {
    name: "Barrenación",
    data: [
      { x: "Rampa 950", y: 0 },
      { x: "Muck Bay 1000", y: 0 },
      { x: "Frente 1042 N", y: 0 },
      { x: "Frente 1042 S", y: 0 },
      { x: "Frente 1005 N", y: 0 },
      { x: "Frente 1005 S", y: 1 },
      { x: "Muck Bay 1042", y: 0 },
      { x: "Rampa 980", y: 0.7 }
    ]
  },
  {
    name: "Carga",
    data: [
      { x: "Rampa 950", y: 0 },
      { x: "Muck Bay 1000", y: 0 },
      { x: "Frente 1042 N", y: 0 },
      { x: "Frente 1042 S", y: 0 },
      { x: "Frente 1005 N", y: 0 },
      { x: "Frente 1005 S", y: 1 },
      { x: "Muck Bay 1042", y: 0 },
      { x: "Rampa 980", y: 0 }
    ]
  },
  {
    name: "Vol",
    data: [
      { x: "Rampa 950", y: 0 },
      { x: "Muck Bay 1000", y: 0 },
      { x: "Frente 1042 N", y: 0 },
      { x: "Frente 1042 S", y: 0 },
      { x: "Frente 1005 N", y: 0 },
      { x: "Frente 1005 S", y: 1 },
      { x: "Muck Bay 1042", y: 0 },
      { x: "Rampa 980", y: 0 }
    ]
  },
  {
    name: "Plan",
    data: [
      { x: "Rampa 950", y: 4 },
      { x: "Muck Bay 1000", y: 0 },
      { x: "Frente 1042 N", y: 0 },
      { x: "Frente 1042 S", y: 1 },
      { x: "Frente 1005 N", y: 0 },
      { x: "Frente 1005 S", y: 0 },
      { x: "Muck Bay 1042", y: 2.2 },
      { x: "Rampa 980", y: 1.3 }
    ]
  }
].map(el => {
  return {
    name: el.name,
    data: el.data.slice(0, 4)
  }
})