import { useEffect, useState } from "react";
import { getInfoGeneralMina } from "../services/operaciones";
import { formatearSoloFechaISO } from "../commons/FormatearFecha";

export const useGetInfoGeneralMina = (idAreaOperacion, fecha) => {
  const [infoGeneralMina, setInfoGeneralMina] = useState([]);
  const [loadingInfoGeneral, setLoadingInfoGeneral] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const controller = new AbortController();

      setLoadingInfoGeneral(true);

      const { success, data } = await getInfoGeneralMina(
        idAreaOperacion,
        formatearSoloFechaISO(fecha),
        controller
      );

      if (success) {
        setInfoGeneralMina(data);
      }

      setLoadingInfoGeneral(false);
    }

    fetchData();
  }, [idAreaOperacion, fecha]);

  return {
    infoGeneralMina,
    loadingInfoGeneral,
  };
};
