import React from "react";

import ErrorIcon from "@mui/icons-material/Error";
import { Box, Grid } from "@mui/material";

export const PlanNoCumpleNotify = ({ data = [] }) => {
  return (
    <Box
      style={{ marginTop: "1.7rem" }}
      sx={{
        gridRowStart: 2,
        gridColumnStart: 5,
        gridColumnEnd: 6,
        width: "100%",
        display: "flex",
        rowGap: ".35rem",
        flexDirection: "column"
      }}>
      {data.map((el, index) => (
        <Grid
          container
          key={index}
          display={"flex"}
          alignItems={"center"}
          sx={{
            height: 42.6
          }}>
          <Grid
            container
            alignItems={"center"}
            flexDirection={"row"}
            flexWrap={"nowrap"}
            style={{ height: "100%" }}
          >
            <Grid sm={2} item textAlign={"center"}>
              {el.comentario ? <ErrorIcon sx={{ margin: "0" }} fontSize="small" color="error" /> : null}
            </Grid>
            <Grid
              sm={10}
              display={"flex"}
              alignItems={"center"}
              item
              style={{
                backgroundColor: el.comentario ? "#F3F3F3" : "#FFFFFF",
                width: "100%",
                minHeight: "100%",
                clipPath:
                  "polygon(20px 0%, 100% 0%, 100% 100%, 20px 100%, 0% 50%)",
                paddingLeft: "16px",
                paddingRight: "6px",
                paddingBlock: ".728rem",
                fontSize: "10px",
              }}>
              <span style={{ fontSize: "13px", textAlign: "left" }}>
                {el?.comentario}
              </span>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
