import { format } from "date-fns";


export function obtenerFechaEnFormato() {
  // Obtén la fecha actual
  const fechaActual = new Date();

  // Formatea la fecha en el formato "dia-mes-año"
  const fechaFormateada = format(fechaActual, 'dd-MM-yyyy');

  return fechaFormateada;
}

export function decimalToHexadecimal(decimalNumber) {
  // Convierte el número a hexadecimal
  const hexadecimalString = decimalNumber.toString(16).toUpperCase();

  return hexadecimalString;
}

export function translateMachineName(name, t, idMina = 0){
  const [type, id] = name?.split(' ');
  if(type.toLowerCase() == "dumper" && idMina == 24){
    const translatedType = t(type.toLowerCase());
    return `${translatedType} ${id}`;
  }else{
    return name
  }
}

export function getCurrentShift(date) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const timeInTimezone = new Intl.DateTimeFormat('en-US', {
    hour: "numeric",
    hour12: false,
    timeZone: timezone
  }).format(date);

  const hour = parseInt(timeInTimezone, 10);

  return (hour >= 7 && hour < 19) ? "Dia" : "Noche";
}