import React from "react";
import "./CustomYAxis.css";

export function CustomYAxis({ columns }) {

  return (
    <section className="custom-yaxis-section">
      {columns?.map((col) => {
        return (
          <div 
            key={col.name.replaceAll("_", "\x20")}
            className="custom-yaxis-item"
          >
            <p>{col.name.replaceAll("_", " ")}</p>
            <section className="metas-section">
              <span>{col.metaActual || 0}</span>
              <span>/ {col.metaFinal || 0}</span>
            </section>
          </div>
        )
      })}
    </section>
  );
}
