import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { usePageTimingContext } from '../../context/PageTimingContext';
import NavBar from '../../components/organisms/NavBar';
import {
    Container
  } from "@material-ui/core";
  import {
    Card,
    Grid,
    Link,
    Typography,
  } from "@mui/material";
  import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none', 
    overflowY:'hidden'
  };

  function get_current_datetime(zonaHoraria){
    let fechaFinal;

    const fechaHoraPais = new Date().toLocaleString("en-US", {
      timeZone: zonaHoraria, 
      hour12: false 
    });
    
    const [fecha, hora] = fechaHoraPais.split(", "); 
    const [horas, minutos] = hora.split(":"); 
    const horaActual = parseInt(horas, 10);     
    const fechaActual = new Date(fecha);
    
    if (horaActual > 11) {
      fechaFinal = fechaActual.toISOString().split("T")[0]; 
    } else {
      fechaActual.setDate(fechaActual.getDate() - 1);
      fechaFinal = fechaActual.toISOString().split("T")[0]; 
    }
    return fechaFinal;
    
  }

  const parseEndpointQuickSight=(cadena, fecha1, fecha2)=>{
    let newCadena = cadena
    .replace("replacefechainicio", fecha1)
    .replace("replacefechafin", fecha2); 
    return newCadena;
  }

const QuickSight = ({idMina, nombreMina, endpointQuickSight, zonaHoraria, diff_segundos, recarga_automatica}) => {
    const HALFHOUR_IN_MS = 30 * 60 * 1000;
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { sendTime } = usePageTimingContext();
    let fecha = get_current_datetime(zonaHoraria)
    const processedEndpoint = parseEndpointQuickSight(endpointQuickSight, fecha, fecha);

    useEffect(() => {
      if(recarga_automatica){
        const interval = setInterval(() => {
          window.location.reload();
        }, HALFHOUR_IN_MS);
  
        return () => {
          sendTime(location.pathname);
          clearInterval(interval);
        }
      }else{
        return () => {
          sendTime(location.pathname);
        }
      }

    }, [])

    return (
       <>
        <NavBar />
        <Card sx={{height:"100vh"}}>
            <iframe
                style={iframeStyle}
                src={processedEndpoint}
                allowFullScreen={true}
                height={'100vh'}
            ></iframe>
        </Card>
       </>
    )
};

export default QuickSight;