import { useEffect, useState } from "react";
import { getEncuentrosControlFrentes } from "../services/controlFrentes";

export const useGetEncuentrosFrentes = (id_area_operacion,fechaInicio=undefined,fechaTermino=undefined) => {
  const [EncuentrosFrentes, setEncuentrosFrentes] = useState([]);
  const [loadingEncuentrosFrentes, setLoadingEncuentrosFrentes] = useState(true);

  useEffect(() => {
    setLoadingEncuentrosFrentes(true)

    getEncuentrosControlFrentes(id_area_operacion,fechaInicio,fechaTermino)
      .then((res) => {
        setEncuentrosFrentes(res.data);
        setLoadingEncuentrosFrentes(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingEncuentrosFrentes(false);
      });
  }, [id_area_operacion, fechaInicio, fechaTermino]);


  return { loadingEncuentrosFrentes, EncuentrosFrentes };
};
