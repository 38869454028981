import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import IconFrentes from "../../assets/icons/frentes_icon.svg";
import IconoControlTiempo from "../../assets/icons/icono-control-tiempo.png";

import { Container, makeStyles } from "@material-ui/core";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { formatDate } from "../../commons/FormatearFecha";
import { getLocaleLanguage } from "../../commons/Idiomas";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico";
import SectionHeader from "../../components/molecules/SectionHeader";
import NavBar from "../../components/organisms/NavBar";
import { RangeBar } from "../../components/organisms/RangeBar";
import { useGetDataPlanControlFrentes } from "../../hooks/controlFrentes/useGetDataPlanControlFrentes";
import { useGetInfoGeneralMina } from "../../hooks/useGetInfoGeneralMina";
import { LeyendaControlFrente } from "./LeyendaControlFrente";
import { getCurrentShift } from "../../commons/utiles";

const useStyles = makeStyles({
  shiftButton: {
    backgroundColor: "#2f4554",
    color: "#fff",
    padding: "5px 30px",
    fontSize: "14px",
    borderRadius: "4px",
    border: "none",
    margin: "3px",
    height: "38px",
    width: "160px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#00b7c4"
    }
  },
  unSelectedShiftButton: {
    backgroundColor: "#f4f4f4",
    color: "#000"
  },
  datepicker: {
    width: "190px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {
        borderColor: "error.main"
      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main"
        /* fontSize:"14.4px" */
      }
    },

    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#00B7C4"
      }
    }
  }
});

export default function ControlDeFrentes({ idMina, nombreMina, rutaMina }) {
  const [date, setDate] = useState(new Date());
  const [idTurno, setIdTurno] = useState(null);
  const [idSubMina, setIdSubMina] = useState("");

  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const dataRutaBreadCrumb = [
    { id: 1, endpoint: "/", texto: `Mine-Watch` },
    { id: 2, texto: `${t("minaSingular")} ${nombreMina}` },
    { id: 3, texto: t("control_frentes"), destacar: true }
  ];

  const textoTurno = `${t("turno").toUpperCase()}`;
  const formatedDate = formatDate(
    date,
    i18n.language === "es" ? "es-CL" : "en-EN",
    {
      day: "numeric",
      month: "long"
    }
  );

  const { infoGeneralMina } = useGetInfoGeneralMina(idMina, date.toISOString());
  const {
    dataPlanControlFrentes,
    fetchPlanControlFrentes,
    loadingPlanControlFrentes,
    errorPlanControlFrentes
  } = useGetDataPlanControlFrentes();

  const subMinas = useMemo(() => infoGeneralMina?.subminas, [infoGeneralMina?.subminas]);

  const handleChangeTurno = (idMina, turno) => {
    const filteredSubMina = subMinas?.filter((subMina) => subMina?.id === idMina);
    const newIdTurno = filteredSubMina[0]?.turnos?.filter((t) => t?.nombre === turno)[0]?.id_turno;

    setIdTurno(newIdTurno);
  }

  const idTurnoDiaSantaRosa = subMinas?.[1]?.turnos?.[0]?.id_turno;
  const idSubMinaSantaRosa = subMinas?.[1]?.id;
  const currentShift = getCurrentShift(date);
  
  const memoizedFetchPlanControlFrentes = useCallback(
    async (idSubMina, date, idTurno) => {
      await fetchPlanControlFrentes(idSubMina, date, idTurno);
    },
    [fetchPlanControlFrentes]
  );
  
  useEffect(() => {
    if (idSubMina && idTurno) {
      memoizedFetchPlanControlFrentes(
        idSubMina,
        date,
        idTurno
      );
    }
  }, [idSubMina, date, idTurno]);
  
  useEffect(() => {  
    if (idSubMinaSantaRosa) {
      handleChangeTurno(idSubMinaSantaRosa, currentShift);
      setIdSubMina(idSubMinaSantaRosa);
    }
  }, [subMinas]);

  return (
    <>
      <NavBar />
      <Container>
        <Grid
          container
          alignItems="center"
          justifyContent="start"
          sx={{ py: 3 }}>
          <BreadCrumbsGenerico
            dataRuta={dataRutaBreadCrumb}
            nombreMina={nombreMina}
          />
        </Grid>
        <Card sx={{ padding: "25px" }}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={6}>
              <SectionHeader
                title={t("control_frentes")}
                subtitle={formatedDate}
                icon={
                  <img
                    src={IconFrentes}
                    width="125px"
                    alt=" Icono control de frentes"
                  />
                }
                colorTitle="#00B7C4"
                aumentarFuente={3}
                bold={true}>
                <CalendarMonthIcon
                  style={{
                    fontSize: "25px",
                    marginRight: "10px",
                    cursor: "pointer",
                    color: "#000000",
                    pointerEvents: "none"
                  }}
                />
              </SectionHeader>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end" sx={{ marginBlock: "6px" }}> 
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocaleLanguage(i18n.language)}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  maxDate={new Date()}
                  format="dd/MM/yyyy"
                  cancelLabel={t("cancelar")}
                  size="small"
                  inputVariant="outlined"
                  value={date}
                  onChange={setDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item container xs={6} flexWrap="nowrap" marginInlineEnd={3} justifyContent="flex-end" alignItems="center">
              <button
                disabled={idSubMina.length < 1}
                className={`${classes.shiftButton} ${idTurno !== "" && classes.unSelectedShiftButton}`}
                onClick={() => handleChangeTurno(idSubMina, "Dia")}>
                Turno dia
              </button>
              <button
                disabled={idSubMina.length < 1}
                className={`${classes.shiftButton} ${idTurno !== "" && classes.unSelectedShiftButton}`}
                onClick={() => handleChangeTurno(idSubMina, "Noche")}>
                Turno noche
              </button>
            </Grid>
            <Grid item container xs={3} justifyContent="flex-end">
              <FormControl fullWidth sx={{ maxWidth: 250 }} variant="outlined">
                <InputLabel>Mina</InputLabel>
                <Select
                  label="Mina"
                  value={idSubMina}
                  onChange={(e) => setIdSubMina(e.target.value)}>
                  <MenuItem disabled value="">
                    Selecciona una Mina
                  </MenuItem>
                  {subMinas?.map((mina) => {
                    return (
                      <MenuItem key={mina.id} value={mina.id}>
                        {mina.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <SectionHeader
            title={t("plan_de_frentes")}
            subtitle={
              <Typography
                sx={{
                  color: "#00B7C4",
                  fontStyle: "normal",
                  fontWeight: "bold"
                }}>
                {textoTurno}
              </Typography>
            }
            icon={
              <img
                src={IconoControlTiempo}
                width="35px"
                alt=" Icono Control de Tiempo"
              />
            }
          />
          <Box sx={{ mt: "2.5rem" }}>
            <LeyendaControlFrente />
          </Box>
          <section style={{ overflowX: "scroll", overflowY: "hidden" }}>
            <RangeBar
              data={dataPlanControlFrentes}
              loading={loadingPlanControlFrentes}
              error={errorPlanControlFrentes}
            />
          </section>
        </Card>
      </Container>
    </>
  );
}
