import React from "react";
import "./MeetsTarget.css";

export function MeetsTarget({ data }) {
  if (!data) return null;

  return (
    <section className="meets-section">
      {data.map((el) => {
        const hasMetasAvanzadas = el.metros_avance !== 0;
        return (
          <div className="meets">
            {hasMetasAvanzadas ? (
              <p className="meets-paragraph completed">
                + {el.metros_avance}
                <div className="star" />
              </p>
            ) : (
              <p className="not-completed meets-paragraph">{el.metros_avance}</p>
            )}
          </div>
        );
      })}
    </section>
  );
}
