import {
  Divider, Drawer,
  List, ListItem,
  ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { ListItemButton } from "@mui/material";
import '../../translator';
import { useTranslation } from 'react-i18next';
import { useContext } from "react";
import { GeneralMinaContext } from "../../context/GeneralMina";

const useStyles = makeStyles({
  list: {
    width: 220
  },
  info: {
    "text-align": "center",
    "font-weight": "bold"
  },
  paper: {
    background: "#2f4554"
  }
});

const NavDrawer = props => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const {onHandleGetEndpoint} = useContext(GeneralMinaContext);
  const [open, setOpen] = React.useState(true);
  const [expandMina, setExpandMina] = React.useState(true)

  const handleClickMinas = () => {
    setOpen(!open);
  };

  const handleClickExpandMina = () => {
    setExpandMina(!expandMina);
  };

  return (
    
    <Drawer
      classes={{ paper: classes.paper }}
      anchor="left"
      open={props.drawerOpened}
      onClose={props.toggleDrawer(false)}
    >
      <div
        className={classes.list}
        //onClick={props.toggleDrawer(false)}
        onKeyDown={props.toggleDrawer(false)}
        style={{height:"100%", color:"white"}}
      >
        
          <List>
            <ListItem className={classes.info}>
              <ListItemText primary="Menú"  />
            </ListItem>
          </List>
          <Divider />

        <ListItemButton onClick={handleClickMinas}>
          <ListItemText primary="Minas" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout={"auto"} unmountOnExit>
          {
            onHandleGetEndpoint().map(ruta =>(
              <div key={ruta.id}>
                  <ListItemButton onClick={()=>handleClickExpandMina()}>
                    <ListItemText primary={ruta.nombre_publico}/>
                    {expandMina? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={expandMina} timeout="auto" unmountOnExit>

                    {
                      !([24].includes(ruta.id_mina)) && (
                      <>
                        <List key={"op2"} component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }} component={Link} to={`/operaciones/${ruta.endpoint}`}>
                            <ListItemText primary={t("operaciones")} />
                          </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }} component={Link} to={`/justificacion/macro/${ruta.endpoint}`}>
                            <ListItemText primary={t("justificacion")} />
                          </ListItemButton>
                        </List>
                      </>
                      )
                    }
                    {
                      !([4, 11, 24].includes(ruta.id_mina)) && (
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }} component={Link} to={`/administracion/${ruta.endpoint}`}>
                            <ListItemText primary={t("administracion")} />
                          </ListItemButton>
                        </List>
                      )
                    }

                    {
                      [16].includes(ruta.id_mina ) && (
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }} component={Link} to={`/control_frentes/${ruta.endpoint}`}>
                            <ListItemText primary={t("control_frentes")} />
                          </ListItemButton>
                        </List>
                      )
                    }
                    {
                      [24].includes(ruta.id_mina ) && (
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }} component={Link} to={`/control_de_frentes/${ruta.endpoint}`}>
                            <ListItemText primary={t("control_de_frentes")} />
                          </ListItemButton>
                        </List>
                      )
                    }
                      {
                        ruta.id_mina == 24 && 
                        <>
                          <List key={"op2"} component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to={`/control_diario/${ruta.endpoint}`}>
                              <ListItemText primary={t("control_diario")} />
                            </ListItemButton>
                          </List> 
                        </>
                      }
                      {
                        //solo para vigolab temporal
                        ruta.id_mina  == 1 && (
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }} component={Link} to={`/analytics/${ruta.endpoint}`}>
                            <ListItemText primary={t("Analytics")} />
                          </ListItemButton>
                        </List>
                        )
                      }
                      {
                        ruta?.endpoint_externo?.length > 0 && (
                          ruta?.endpoint_externo?.map((rutaAux, index) =>(
                          <List key={'embedded'+index} component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }} component={Link} to={`/${rutaAux.nombre}/${ruta.endpoint}`}>
                            <ListItemText primary={t(rutaAux.nombre)} />
                          </ListItemButton>
                          </List>
                        ))
                        )
                      }
                  </Collapse>
                </div>
              
            ))
          }
        </Collapse>
        

      </div>



    </Drawer>
 
  );
};
export default NavDrawer;
