import { useEffect, useState } from "react";
import { getActividadFrentes } from "../../services/controlFrentes";
import { normalizeText } from "../../commons/Formatos";

export function useGetActividadFrentes() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const { success, data, error } = await getActividadFrentes();

      if (success) {
        const newData = data.filter((item) => item.label !== "Desconocido").map((item) => {
          return {
            ...item,
            formatedLabel: normalizeText(item.label)
          }
        })

        setData(newData);
      } else {
        setError(error);
      }

      setLoading(false);
    }

    fetchData();
  }, []);

  return { activities: data, loadingActivities: loading, errorActivities: error };

}