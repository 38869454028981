import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@mui/icons-material/Error";
import { Grid } from "@mui/material";

export const LeyendaControlFrente = () => {
  const useStyles = makeStyles({
    prodDes: {
      color: "#2f4554",
      fontSize: "12px !important"
    }
  });
  const classes = useStyles();
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          xs={4}
          md={3}
          lg={2}
          container
          item
          sx={{ borderRight: "2px solid #d7d7d7" }}
          alignItems={"center"}>
          <SemiSquare color={"#2C4352"} left={true} />
          <Square color={"#3167A9"} />
          <Square color={"#4A86E8"} />
          <Square color={"#01B7C4"} />
          <Square color={"#139EA8"} />
          <Square color={"#097B83"} />
          <SemiSquare color={"#023E53"} left={false} />
          <span className={classes.prodDes} style={{ marginLeft: "3px" }}>
            Avance actual
          </span>
          <Grid
            item
            sx={{ paddingRight: "20px", borderRight: "2px solid #d7d7d7" }}
          />
        </Grid>
        <Grid
          xs={2}
          md={2}
          lg={1}
          container
          item
          sx={{ padding: "0 10px", borderRight: "2px solid #d7d7d7" }}
          alignItems={"center"}>
          <Circle />
          <span className={classes.prodDes} style={{ margin: "0 15px" }}>
            Plan
          </span>
        </Grid>
        <Grid
          xs={4}
          md={3}
          lg={2}
          container
          item
          sx={{ padding: "0 10px" }}
          alignItems={"center"}>
          <ErrorIcon sx={{ margin: "0" }} fontSize="small" color="error" />
          <span className={classes.prodDes} style={{ margin: "0 16px" }}>
            Plan no se cumple
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Circle = () => {
  return (
    <span
      style={{
        marginLeft: "5px",
        height: "15px",
        width: "15px",
        backgroundColor: "#F89821",
        borderRadius: "50%",
        display: "inline-block"
      }}
    />
  );
};

const SemiSquare = ({ color, left = false }) => {
  return (
    <span
      style={{
        height: "13px",
        width: "13px",
        backgroundColor: color,
        borderRadius: left ? "50% 0 0 50%" : "0 50% 50% 0",
        display: "inline-block",
        marginRight: !left ? "5px" : "0"
      }}
    />
  );
};

const Square = ({ color }) => {
  return (
    <span
      style={{
        height: "13px",
        width: "13px",
        backgroundColor: color,
        display: "inline-block"
      }}
    />
  );
};
