import { useState } from "react";
import { formatearSoloFechaISO } from "../../commons/FormatearFecha";
import { getDataPlanControlFrentes } from "../../services/controlFrentes";

export function useGetDataPlanControlFrentes() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function fetchPlanControlFrentes(idAreaOperacion, fecha, idTurno) {
    setLoading(true);

    const parsedFecha = formatearSoloFechaISO(fecha.toISOString());
    const { success, data, error } = await getDataPlanControlFrentes({
      idAreaOperacion,
      fecha: parsedFecha,
      idTurno
    });

    const newData = data.toSorted((a, b) => a.id_frente - b.id_frente);

    if (success) setData(newData);
    else setError(error);

    setLoading(false);
  }

  return {
    dataPlanControlFrentes: data,
    fetchPlanControlFrentes,
    loadingPlanControlFrentes: loading,
    errorPlanControlFrentes: error
  };
}
